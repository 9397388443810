import { api } from "@/base/utils/request";

export const getApprovalDetail = (data) => {
  return api({
    url: '/admin/approval/record/detail',
    method: 'post',
    data
  })
}

/**
 * 不知道干嘛用的
 * @param data
 */
export const updateApprovalStatus = (data) => {
  return api({
    url: '/admin/approval/record/approve',
    method: 'post',
    data
  })
}
