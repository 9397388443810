<template>
  <div v-if="responseData" class="checked-users" :class="{single}">
    <div v-if="responseData.forward_users_infos !== undefined" class="progress-item">
      <div class="progress-title">转审人<text class="required">*</text></div>
      <div class="progress-users flex">
        <div v-for="(user, userI) in responseData.forward_users_infos" class="user-item flex-align-center" :key="user.id">
          <base-avatar :url="user.avatar || defaultAvatar" :size="32" />
          <text class="user-name">{{user.nickname}}</text>
          <uni-icons type="closeempty" color="#808080" style="line-height: 1;" @click.native="removeUser('forward', userI)" />
          <!-- TODO: 已审核状态 -->
<!--          <uni-icons type="checkbox-filled" size="20" color="var(&#45;&#45;theme-color)" style="line-height: 1;position: absolute;bottom:-2px;right: -4px" />-->
        </div>
        <div v-if="!responseData.forward_users_infos.length" class="add-user flex-center" @click="addUser('forward')">
          <uni-icons type="plusempty" color="var(--theme-color)" style="line-height: 1" />
        </div>
      </div>
    </div>
    <div v-if="responseData.applyUser !== undefined" class="progress-item">
      <div class="progress-title">发起人<text class="required"></text></div>
      <div class="progress-users flex">
<!--        <div class="user-item flex-align-center">-->
          <div v-for="(user, userIndex) in [responseData.applyUser]" :key="user.id" class="user-item flex-align-center" :title="user && user.title_name || ''">
<!--            <i class="el-icon-error cursor-pointer" @click="myValue.splice(userIndex, 1)" />-->
            <list-image :src="fixImageUrl(user && user.avatar || defaultAvatar, 150)" :preview="false"
                        :width="60" :height="60" fit="cover" :border-radius="4" />
            <span class="name">{{user && user.nickname || ''}}</span>
          </div>
<!--        </div>-->
      </div>
    </div>
    <div v-if="responseData.approval_users !== undefined && (responseData.approval_users.length || !disabled)"
          class="progress-item">
      <div class="progress-title">{{responseData.approval_text||'审批人'}}<text class="required">*</text></div>
      <div class="progress-users flex">
        <div class="single-user flex-col" v-for="(user, userI) in responseData.approval_users" :key="user.id">
          <div class="flex-align-center">
            <div class="user-item flex-align-center" :title="user && user.title_name || ''">
              <!--            <i class="el-icon-error cursor-pointer" @click="responseData.approval_users.splice(userI, 1)" />-->
              <list-image :src="fixImageUrl(user && user.avatar || (user.user && user.user.avatar || '') || defaultAvatar, 150)" :preview="false"
                          :width="60" :height="60" fit="cover" :border-radius="4" />
              <span class="name">{{user && user.nickname || (user.user && user.user.nickname || '') || ''}}</span>
            <img v-if="responseData.approval_users && responseData.approval_users[userI].sign" class="signature" :src="responseData.approval_users[userI].sign" mode="aspectFit" />
              <!-- TODO: 已审核状态 -->
              <template v-if="responseData.approval_users[userI]">
                <!-- 已通过 -->
                <i v-if="responseData.approval_users[userI].status === 1" class="el-icon-circle-check bg" style="line-height: 1;position: absolute;bottom:0;left: 56px;color: white;" />
                <i v-if="responseData.approval_users[userI].status === 1" class="el-icon-success" style="line-height: 1;position: absolute;bottom:0;left: 56px;color:#13ce66;" />
                <!-- 已驳回 -->
                <i v-else-if="responseData.approval_users[userI].status === 2" class="el-icon-circle-close" style="line-height: 1;position: absolute;bottom:0;left: 56px;color:white;" />
                <i v-else-if="responseData.approval_users[userI].status === 2" class="el-icon-error" style="line-height: 1;position: absolute;bottom:0;left: 56px;color:red;" />
                <!-- 已转审 -->
                <i v-else-if="responseData.approval_users[userI].status === 3" class="el-icon-s-promotion" style="line-height: 1;position: absolute;bottom:0;left: 56px;color:gray;" />
              </template>
            </div>
          </div>

            <i v-if="!disabled && responseData.approval_for === 3" type="closeempty" color="#808080" style="line-height: 1;" @click.native="removeUser('approval', userI)" />

          <text v-if="responseData.approval_users[userI].update_time && responseData.approval_users[userI].status" class="time">{{responseData.approval_users[userI].update_time}}</text>
        </div>
        <!-- approval_for 审批人设置，成员设置（1:指定成员，2:本人，3:自选 -->
        <div v-if="!disabled && responseData.approval_for !== 1 && !(
            !responseData.approval_multiple && responseData.approval_users_infos.length
            )" class="add-user flex-center" @click="addUser('approval')">
          <uni-icons type="plusempty" color="var(--theme-color)" style="line-height: 1" />
        </div>
      </div>
    </div>
    <div v-if="responseData.copy_users !== undefined && (responseData.copy_users.length || !disabled)" class="progress-item">
      <div class="progress-title">{{responseData.copy_text || '抄送人'}}<text class="required"></text></div>
      <div class="progress-users flex">
        <div v-for="(user, userI) in responseData.copy_users" class="user-item flex-align-center" :key="user.id">
          <list-image :src="fixImageUrl(user && user.avatar || (user.user && user.user.avatar || '') || defaultAvatar, 150)" :preview="false"
                      :width="60" :height="60" fit="cover" :border-radius="4" />
          <span class="name">{{user && user.nickname || (user.user && user.user.nickname || '') || ''}}</span>
<!--          <i v-if="!disabled && responseData.copy_for === 3" type="closeempty" color="#808080" style="line-height: 1;" @click.native="removeUser('copy', userI)" />-->
        </div>
<!--        <div v-if="!disabled && responseData.copy_for !== 1" class="add-user flex-center" @click="addUser('copy')">-->
<!--          <i class="close" type="plusempty" color="var(&#45;&#45;theme-color)" style="line-height: 1" />-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import ListImage from "@/base/components/List/ListImage";
export default {
  name: 'checked-users',
  components: {ListImage},
  props: {
    value: {
      type: Object,
      default: null
    },
    isHide: Boolean,
    users: Array
  },
  data() {
    return {
      // approval | copy
      userType: 'approval',
      checkedUsers: [],
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
    }
  },
  computed: {
    responseData: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    single() {
      const arr = []
      if (this.responseData.approval_users !== undefined) arr.push(1)
      if (this.responseData.copy_users !== undefined) arr.push(1)
      if (this.responseData.forward_users !== undefined) arr.push(1)
      if (this.responseData.create_users !== undefined) arr.push(1)
      return arr.length === 1
    },
    disabled() {
      return this.responseData.applyUser
      // && this.responseData.copy_users_infos.length === 0
      //     && this.responseData.copy_users_infos.length === 0 || (
      //   !this.responseData.applyUser
      // )
    }
  },
  watch: {
    isHide(newValue) {
      if (newValue) {
        this.checkedUsers = []
      } else {
        // 选好了的审批人，抄送人
        this.checkedUsers = [...this.users]

        // 比对有没有重复的
        let valid = true
        const users = this.responseData[`${this.userType}_users_infos`]
        users.forEach(u => {
          const uid = u.user_id || u.id
          this.checkedUsers.forEach(uu => {
            const uuid = uu.user_id || uu.id
            if (uid === uuid) valid = false
          })
        })

        if (valid) {
          this.responseData[`${this.userType}_users_infos`].push(...this.checkedUsers)
          // this.responseData[`${this.userType}_users`].push(...this.checkedUsers.map(el => el.user_id || el.id))
          this.$emit('input', this.responseData)
        } else {
          uni.showToast({
            title: '不能重复选择审批人',
            icon: 'none'
          })
        }
      }
    }
  },
  methods: {
    addUser(type) {
      this.userType = type
      this.checkedUsers = []
      let includedIds = []
      if (this.responseData[`${type}_for`] === 2) {
        /* 审批人为本人 */
        includedIds.push(this.userInfo.id)
      } else if (this.responseData[`${type}_for`]) {
        /* 审批人为指定成员 */
        includedIds = [...this.responseData[`${type}_users`]]
      }
      /* 限定可选范围 */
      this.$store.commit('setStateAttr', {
        key: 'includedUserIds',
        val: includedIds
      })

      let multiple = 'n'
      if (this.responseData[`${type}_multiple`]) multiple = 'y'
      if (type === 'copy') multiple = 'y'

      this.jumpTo('path', {
        system_page: 'mail_list',
      }, {
        from: 'approval',
        m: multiple,
        // <!-- approval_for 审批人设置，成员设置（1:指定成员，2:本人，3:自选 -->
        ids: includedIds.length ? 1 : 0 // 0：不限范围，1：限定范围，传入id过滤，读取 store.state.includedUserIds
      })
    },
    removeUser(type, index) {
      // this.responseData[`${type}_users`].splice(index, 1)
      this.responseData[`${type}_users_infos`].splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .checked-users {
    &.single {
      .progress-title {
        &:before {
          display: none;
        }
      }

      .progress-users {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  .user-item, .add-user {
    /*height: 40px;*/
    margin-bottom: 13px;
  }

  .user-item {
    /*border-radius: 51px;*/
    border: 1px solid rgba(0,0,0,0.1);
    background: rgba(248,248,248,1);
    padding: 4px 12px 4px 4px;
    margin-right: $space;
    position: relative;

    .user-name {
      color: rgba(0,0,0,1);
      font-size: 14px;
      margin-left: 8px;
      margin-right: 8px;
      @include nowrap();
    }
  }

  .signature {
    /*width: 64px;*/
    /*height: 48px;*/
    height: 60px;
    width: auto;
    object-fit: contain;
  }

  .add-user {
    width: 40px;
    border-radius: 20px;
    background: var(--secondary-color);
  }

  .progress-title {
    font-size: 12px;

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border: 2px solid rgba(85,97,115,1);
      display: inline-block;
      border-radius: 50%;
      margin-right: 8px;
    }

    .required {
      color: $danger;
      display: inline-block;
      margin-left: 2px;
    }
  }

  .progress-users {
    padding-left: $space;
    border-left: 2px solid rgba(85,97,115,.5);
    padding-top: 9px;
    flex-wrap: wrap;
    margin: 3px 0 3px (8 - 2) * 0.5px;
  }

  .progress-item {
    &:last-child {
      .progress-users {
        border-color: transparent;
      }
    }
  }

  .progress-item + .progress-item {
    /*    position: relative;

        &:before {
          content: "";
          height: 100%;
          width: 0;
          border: 1px solid rgba(85,97,115,1);
          margin-top: -100%;
        }*/
  }

  .single-user {
    .time {
      margin-left: 4px;
      margin-top: 4px;
      margin-bottom: 13px;
      color: rgba(179,179,179,1);
      font-size: 12px;
    }
  }

  .user-item {
    position: relative;
    /*width: 60px;*/
    margin-right: 25px;
    margin-bottom: 25px;

    .list-image {
      margin-right: 8px;
      flex-shrink: 0;
    }

    $icon-s: 18px;

    .el-icon-error {
      position: absolute;
      right: -$icon-s * 0.5;
      top: -$icon-s * 0.5;
      font-size: $icon-s;
      z-index: 1;
      opacity: .7;
    }

    .name {
      text-align: left;
      display: block;
      line-height: 1.5;
      /*margin: 10px 0 0;*/
      word-break: break-all;
    }
  }
</style>
