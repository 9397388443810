<template>
  <el-form class="small-form form-detail" size="medium" label-position="left">
    <div v-for="config in arr" :key="config.extra.timestamp" class="card-form-item" :class="{media: config.field === 'attachment'}" @click="handleClick(config)">
      <el-form-item :label="(config.props.normal.label||'--') + '：'">
        <template v-if="config.field !== 'attachment'">
        <span v-if="config.user_data && config.user_data.address">{{
          config.user_data.address_name}}{{
          config.user_data.address_name ? '（' : ''}}{{
          config.user_data.address
          | placeholder}}{{
          config.user_data.address_name ? '）' : ''}}</span>
          <span v-else>{{config.user_data | placeholder}}</span>
        </template>
        <template v-else>
          <div v-if="config.user_data" class="grid3 grid">
            <div v-for="m in config.user_data" class="grid-item" :key="m.url">
              <list-image :src="m.thumbnail || m.url" :width="100" :height="100" />
              <!--            <base-load-image @click.stop="image_preview(config.user_data, m.thumbnail || m.url)" :src="m.thumbnail || m.url" :size="500" proportion="100%" />-->
              <!--            <img v-if="m.type === 'video'" @click="mixin__video_play(m.url)" class="play-icon" src="/static/common/icons/video-play.png" />-->
            </div>
          </div>
          <span v-else>{{config.user_data | placeholder}}</span>
        </template>
      </el-form-item>
<!--      <span class="label">{{config.props.normal.label | placeholder}}：</span>-->
    </div>
  </el-form>
</template>

<script>
import ListImage from "@/base/components/List/ListImage";
export default {
  name: 'form-configs',
  components: {ListImage},
  props: {
    arr: Array,
    readonly: Boolean,
  },
  methods: {
    handleClick(e) {
      if (this.readonly) return
      if (e.field === 'location') {
        // TODO: 打开地图搜索位置
        // uni.openLocation({
        //   latitude: e.user_data.lat,
        //   longitude: e.user_data.lng,
        //   name: e.user_data.address_name,
        //   address: e.user_data.address
        // })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-form-item {
    /*color: rgba(128,128,128,1);*/
    font-size: 14px;
    line-height: 32px;

    .label {
      margin-right: 4px;
      display: inline-block;
      white-space: nowrap;
    }

    &+.card-form-item {
      /*margin-top: 8px;*/
    }

    .media {
      width: 100%;
    }

    .grid {
      .grid-item {
        position: relative;
      }

      $video-play-icon-s: 30px;

      .play-icon {
        width: $video-play-icon-s;
        height: $video-play-icon-s;
        position: absolute;
        left: calc(50% - #{$video-play-icon-s * 0.5});
        top: calc(50% - #{$video-play-icon-s * 0.5});
        z-index: 1;
      }
    }
  }
</style>
