<template>
  <div class="container" v-loading="loading">
    <div v-if="responseData" class="flex-col" :style="{
      '--form-margin': 8 + 'px'
    }">
      <div class="single-form-item" :data-last="true">
        <div class="user flex-align-center">
          <el-avatar :src="responseData.applyUser.avatar || defaultAvatar" :size="48" />
          <p class="flex-1">{{responseData.title}}</p>
          <el-tag size="mini" :type="getTagColor(responseData.status)">{{statusArr[responseData.status + 1]}}</el-tag>
        </div>
      </div>
      <div class="single-form-item" :data-last="true">
        <el-form class="medium-form" size="small">
<!--          <label class="config-section-title">审批流程</label>-->
          <el-form-item label="审批编号：">{{responseData.code}}</el-form-item>
          <el-form-item label="提交时间：">{{responseData.create_time}}</el-form-item>
          <!-- 自定义表单 -->
          <form-configs :arr="responseData.form_configs" />
        </el-form>
      </div>
      <div class="single-form-item" :data-last="true">
        <label class="config-section-title">审批流程</label>
<!--        <selected-users v-model="responseData.approval_users" @select="(showMemberSelector = true) && (type = 'apply')" />-->
        <checked-users v-model="responseData" />
      </div>
    </div>
    <fixed-action-bar>
      <el-button @click="$router.back()">返回</el-button>
      <template v-if="responseData && responseData.status === 0">
        <!--      <el-button type="info">转审</el-button>-->
        <el-button type="danger" @click="update(2)">驳回</el-button>
        <el-button type="success" @click="update(1)">同意</el-button>
      </template>
    </fixed-action-bar>
  </div>
</template>

<script>
import { getApprovalDetail, updateApprovalStatus } from "@/modules/approval/api/approval-detail";
import FixedActionBar from "@/base/layout/FixedActionBar";
import FormConfigs from "@/modules/approval/components/approval-detail/FormConfigs";
import CheckedUsers from "@/modules/approval/components/approval-detail/checked-users";
import SelectedUsers from "@/modules/approval/components/template-detail/SelectedUsers";

export default {
  name: "approval-detail",
  components: {SelectedUsers,
    CheckedUsers,
    FormConfigs, FixedActionBar},
  data() {
    return {
      loading: true,
      responseData: null,
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
      statusArr: [
        '全部', '审批中', '已通过', '已驳回', '已撤销', '通过后撤销'
      ],
      type: '',
      showMemberSelector: false,
    }
  },
  created() {
    getApprovalDetail({
      record_id: this.$route.params.id
    }).then(res => {
      this.responseData = res.data
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    })
  },
  methods: {
    update(status) {
      this.loading = true;
      updateApprovalStatus({
        status,
        record_id: this.$route.params.id
      }).then(res => {
        this.$message.success(res.msg);
        this.$router.back();
      }).catch(() => {
        this.loading = false;
      })
    },
    getTagColor(status) {
      /* "status": 0, // 状态（ -1:全部，0：'审批中', 1：'已通过', 2：'已驳回', 3：'已撤销', 4：'通过后撤销'） */
      switch(status) {
        case 0:
          return 'warning'
        case 1:
          return 'success'
        case 2:
          return 'danger'
        default:
          return 'info'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .container {
    min-height: 400px;
  }

  .config-section-title {
    margin-bottom: $space;
    display: block;
  }

  .single-form-item {
    background-color: white;
    /*position: relative;*/
    padding: 0 6px;

    &:not([data-last='true']) + .single-form-item {
      margin-top: $space;
    }

    &[data-last='true'] + .single-form-item,
    {
      margin-top: $space * 2;

    }

    &[data-last='true'],
    &:last-child {

    }

    ::v-deep.el-form-item {
      margin-bottom: 0;
    }
  }

  .user {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: bold;

    .flex-1 {
      margin-left: 13px;
      margin-right: 13px;
      @include nowrap();
    }
  }
</style>
